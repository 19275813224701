import { useEffect, useState } from "react";
import './feed.css'
import '../../App.css'

const Feed = () => {
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cachedFeed = localStorage.getItem('feedData');
    const cacheExpiration = localStorage.getItem('feedDataExpiration');
    const now = new Date().getTime();

    if (cachedFeed && cacheExpiration && now < parseInt(cacheExpiration)) {
      setFeed(JSON.parse(cachedFeed));
      setLoading(false);
    } else {

      fetch('https://api.guidesoft.fi/feed')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch feed');
          }
          return response.json();
        })
        .then((data) => {
          setFeed(data);
          setLoading(false);

          localStorage.setItem('feedData', JSON.stringify(data));
          localStorage.setItem('feedDataExpiration', (now + 10 * 60 * 1000).toString()); // Cache for 10 minutes
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, []);

  if (loading) return <p>Loading feed...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className='container-payments'>
      {feed.map((feed) => (
        <table key={feed.id} id='feedTable'>
          <thead>
            <tr>
              <th>{feed.title}</th>
            </tr>
          </thead>
          <tbody>
            {feed.assets.map((asset => (
              <tr>
                <td>
                  <img
                    src={asset}
                    alt='img'
                  />
                </td>
              </tr>
            )))}
            <tr>
              <td>{feed.body}</td>
            </tr>
          </tbody>
        </table>
      ))}
    </div>
  );


};

export default Feed;
