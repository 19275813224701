import React, { useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import i18n from './translations';
import Payments from './Components/Payments'
import BankInformation from './Components/Bank';
import Feed from './Components/Feed';


const App = () => {
  const [language, setLanguage] = useState('al')

  const lastUpdated = process.env.REACT_APP_DRITA_LATEST_UPDATE || '01.01.2000';

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    localStorage.setItem('language', newLang);
    setLanguage(newLang)
    i18n.changeLanguage(newLang);
  };

  return (
    <>
      <div>
        <ul className='ul-nav'>
          <div className='lastUpdatedDiv'>
            <p className='lastUpdated'>{i18n.t('lastUpdated')}: {lastUpdated}</p>
          </div>
          <li className='li-nav'>
            <a className='li-nav a' href='/'>{i18n.t('paymentsNav').toUpperCase()}</a>
            <a className='li-nav a' href='/feed'>{i18n.t('feedNav').toUpperCase()}</a>
            <a className='li-nav a' href='/bank'>{i18n.t('bankNav').toUpperCase()}</a>
          </li>
          <select value={language} onChange={handleLanguageChange}>
            <option value='al'>Shqip</option>
            <option value='en'>English</option>
            <option value='fi'>Suomi</option>
          </select>
        </ul>
        <Routes>
          <Route path='/' element={<Payments />} />
          <Route path='/feed' element={<Feed />} />
          <Route path='/bank' element={<BankInformation />} />
        </Routes>
      </div >
    </>

  );
}

export default App;