import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    lng: "al",
    fallbackLng: "al",
    interpolation: {
      escapeValue: false,
    },

    resources: {
      al: {
        translation: {
          title: "Pagesat e antarësisë",
          title2: "Shkruaje numrin tënd referent",
          remember: "Më mbaj mend",
          search: "Kërko",
          name: "Emri",
          yearly: "Viti",
          month: "Muaj",
          yearlyPayments: "Pagesat vjetore",
          monthlyPayments: "Pagesat mujore",
          totalPayments: "Pagesat totale",
          payment: "Pagesa",
          paymentsNav: "Pagesat",
          paymentInfo: "Pagesa e antarësisë mund të bëhet me këto shenime",
          lastUpdated: "Përditsimi i fundit",
          total: "Totali",
          bankNav: "Banka",
          feedNav: "Info",
          referenceNumber: "Numri referent",
          bankAccount: "Konto",
          bankTitle: "Informatat të bankes",
          accountNumber: "Numri i kontos",
          accountReceiver: "Përfituesi",
          showRefID: 'Shfaq numrin referent'
        },
      },
      en: {
        translation: {
          title: "Member payments",
          title2: "Enter your reference number",
          remember: "Remember me",
          search: "Search",
          name: "Name",
          yearly: "Year",
          month: "Month",
          yearlyPayments: "Yearly payments",
          monthlyPayments: "Monthly payments",
          totalPayments: "Total payments",
          payment: "Payment",
          paymentsNav: "Payments",
          paymentInfo: "The member fee payment can be done with above information",
          lastUpdated: "Last updated",
          total: "Total",
          bankNav: "Bank",
          feedNav: "Info",
          referenceNumber: "Refernce Number",
          bankAccount: "Account",
          bankTitle: "Bank Account Information",
          accountNumber: "Account number",
          accountReceiver: "Receiver",
          showRefID: 'Show reference number'
        },
      },
      fi: {
        translation: {
          title: "Jäsenmaksut",
          title2: "Syötä viitenumerosi",
          remember: "Muista minut",
          search: "Etsi",
          name: "Nimi",
          yearly: "Vuosi",
          month: "Kuukausi",
          monthlyPayments: "Kuukausimaksut",
          yearlyPayments: "Vuosimaksut",
          totalPayments: "Kaikki maksut",
          payment: "Maksu",
          paymentsNav: "Maksut",
          paymentInfo: "Jäsenmaksun voi tehdä yllä olevilla tiedoilla",
          lastUpdated: "Viimeksi päivitetty",
          total: "Yhteensä",
          bankNav: "Pankki",
          feedNav: "Info",
          referenceNumber: "Viitenumero",
          bankAccount: "Pankkitili",
          bankTitle: "Pankin tiedot",
          accountNumber: "Tilinumero",
          accountReceiver: "Saaja",
          showRefID: 'Näytä viitenumero'
        },
      },
    },
  });

export default i18n;
